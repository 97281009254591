<template>
    <div class="accolades section-wrapper" :class="viewport.is">
        <section>
            <NuxtImg
                v-for="accolade, index in accolades"
                :key="index"
                :src="accolade.src"
                :alt="accolade.imageAlt"
                :class="accolade.class"
                :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                :width="accolade.width"
                :height="accolade.height"
                sizes="xl:10vw lg:10vw md:10vw sm:10vw xs:10vw"
                loading="lazy"
            />
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

export default {
    name: 'Accolades',
    inheritAttrs: false,
    data() {
        return {      
            accolades: [
                {
                    src: '/accolades/accolades-read-our-reviews.png',
                    imageAlt: '4.5 star rating read our reviews on G2',
                    url: 'https://www.g2.com/products/coassemble/reviews?utm_source=review-widget',
                    width: 400,
                    height: 200
                },
                {
                    src: '/accolades/g2_Recommend_2.png',
                    class: 'badge',
                    imageAlt: 'recommended',
                    url: 'https://www.g2.com/products/coassemble/reviews',
                    width: 770,
                    height: 1000
                },
                {
                    src: '/accolades/g2_Leader_winter_2.png',
                    class: 'badge',
                    imageAlt: 'leader',
                    url: 'https://www.g2.com/products/coassemble/reviews',
                    width: 770,
                    height: 1000
                },
                {
                    src: '/accolades/g2_Easiest_Use.png',
                    class: 'badge',
                    imageAlt: 'easiest use',
                    url: 'https://www.g2.com/products/coassemble/reviews',
                    width: 770,
                    height: 1000
                },
                {
                    src: '/accolades/g2_Adoption.png',
                    class: 'badge',
                    imageAlt: 'adoption',
                    url: 'https://www.g2.com/products/coassemble/reviews',
                    width: 770,
                    height: 1000
                },
                {
                    src: '/accolades/accolades-customer-experience.png',
                    class: 'crest',
                    imageAlt: 'customer experience',
                    url: 'https://elearningindustry.com/directory/elearning-software/coassemble',
                    width: 168,
                    height: 168
                },
                {
                    src: '/accolades/accolades-user-experience.png',
                    class: 'crest',
                    imageAlt: 'user experience',
                    url: 'https://elearningindustry.com/directory/elearning-software/coassemble',
                    width: 168,
                    height: 168
                },
                {
                    src: '/accolades/accolades-value-for-money.png',
                    class: 'crest',
                    imageAlt: 'value for money',
                    url: 'https://elearningindustry.com/directory/elearning-software/coassemble',
                    width: 168,
                    height: 168
                },
                {
                    src: '/accolades/accolades-authoring-tools.png',
                    class: 'crest',
                    imageAlt: 'best elearning authoring tools',
                    url: 'https://elearningindustry.com/directory/elearning-software/coassemble',
                    width: 168,
                    height: 168
                },
                {
                    src: '/accolades/accolades-holon-iq.png',
                    imageAlt: '2023 Australia & New Zealand EdTech 50',
                    url: 'https://credentials.holoniq.com/credentials/4a1e9c6b-6439-4487-a746-16d029eff2af',
                    class: 'holon',
                    width: 112,
                    height: 112
                }
            ],
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.accolades {
    padding: 40px;
    section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        img {
            display: flex;
            width: 160px;
            flex: 0 0 auto;
            border-radius: 12px;
            &.badge { width: 70px; }
            &.crest { width: 96px; }
            &.holon { width: 112px; }
        }
    }
}
</style>
